<template>
    <div class="detail-info">
        <table-data
            id="table"
            ref="table"
            :key="tableKey"
            :config="table_config"
            :tableData="table_data"
        >
        </table-data>
    </div> 
</template>

<script>
    import {
        // 业务组件
        TableData,
    } from 'common-local';

    export default {
        name: "DetailInfo",
        components: {
            TableData
        },
        props: {
            tableKey: Number,
            table_config: Object,
            table_data: Object
        }
    }
</script>